import { ReactNode } from "react";
import Head from "next/head";
import Nav from "./Nav";
import Footer from "./Footer";
import TerraWalletNav from "./TerraWalletNav";
import KeplrWalletNav from "./KeplrWalletNav";
const USE_WALLET = process.env.NEXT_PUBLIC_USE_WALLET === "true";
const WALLET = process.env.NEXT_PUBLIC_WALLET || "keplr";

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-base-100 text-base-content">
      <Head>
        <title>{process.env.NEXT_PUBLIC_SITE_TITLE}</title>
        <meta
          name="description"
          content="Cryptech Developments for your next Web3 project"
        />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest"></link>
      </Head>

      {USE_WALLET ? (
        WALLET === "keplr" ? (
          <KeplrWalletNav />
        ) : (
          <TerraWalletNav />
        )
      ) : (
        <Nav />
      )}

      <main className="w-full flex-1">{children}</main>
      <Footer />
    </div>
  );
}
